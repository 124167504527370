export const localeCodes = ["en","es","pt"]

export const localeMessages = {
  "en": [],
  "es": [],
  "pt": [],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true,"dropMessageCompiler":false})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
  nuxtI18nOptions.customBlocks = Object({"defaultSFCLang":"json","globalSFCScope":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("../i18n.config.ts?hash=01802514&config=1" /* webpackChunkName: "__i18n_config_ts_01802514" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"en","name":"English","files":[],"hashes":[],"types":[]}),Object({"code":"es","name":"Español","files":[],"hashes":[],"types":[]}),Object({"code":"pt","name":"Português","files":[],"hashes":[],"types":[]})]
  nuxtI18nOptions.defaultLocale = "en"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = null
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "config"
  nuxtI18nOptions.pages = Object({"tips/index":Object({"en":"/tips","es":"/consejos","pt":"/dicas"}),"gallery/index":Object({"en":"/gallery","es":"/galeria","pt":"/galeria"}),"profile/index":Object({"en":"/profile","es":"/perfil","pt":"/perfil"}),"camera/index":Object({"en":"/camera","es":"/camara","pt":"/camera"}),"intro/index":Object({"en":"/intro","es":"/introduccion","pt":"/introducao"}),"posts/index":Object({"en":"/learn_more","es":"/aprende_mas","pt":"/saiba_mais"}),"images/index":Object({"en":"/images/:id","es":"/imagenes/:id","pt":"/imagens/:id"}),"dropout/index":Object({"en":"/dropout","es":"/dropout","pt":"/dropout"}),"support/index":Object({"en":"/support","es":"/suporte","pt":"/suporte"}),"your_disposal_places/index":Object({"en":"/your_disposal_places","es":"/sus_lugares_de_disposicion","pt":"/seus_locais_de_descarte"})})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true,"dropMessageCompiler":false}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),customBlocks: Object({"defaultSFCLang":"json","globalSFCScope":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","name":"English","files":[],"hashes":[],"types":[]}),Object({"code":"es","name":"Español","files":[],"hashes":[],"types":[]}),Object({"code":"pt","name":"Português","files":[],"hashes":[],"types":[]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const parallelPlugin = false
